import { useEffect, useState } from "react";

const prefixCachedIdxMap = new Map();

const createId = (prefix) => {
    if (typeof prefixCachedIdxMap[prefix] !== 'number') {
        prefixCachedIdxMap[prefix] = 0;
    } else {
        prefixCachedIdxMap[prefix] += 1;
    }

    return `${prefix}_${prefixCachedIdxMap[prefix]}`;
}

export const useUniqueId = (prefix) => {
    const [value, setValue] = useState(createId(prefix));

    useEffect(() => {
        setValue(createId(prefix));
    }, [prefix]);

    return value;
};
