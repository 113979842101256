import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { getIconById } from 'components/Header/img/icons';
import { actions } from '../';

import './MyBets.scss';

const MyBets = () => {
  const b = block('my-bets');
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang);
  const { icon_id } = useSelector(state => state.auth);
  const { myBets } = useSelector(state => state.history);
  const { roundID } = useSelector(state => state.game);
  const currency = useSelector(state => state.auth.currency);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getMyBets());
  }, [dispatch, roundID])

  const resultList = useMemo(() => (
    myBets.map((t, i) => (
      <div key={`${t.value}${t.round_id}${i}`} className={b('item')}>
        <div className={b('column-icon')}>
          <img src={getIconById(icon_id)} className={b('icon')} loading='lazy' alt='icon' />
          {t.nick || t.user_id}
        </div>
        <div className={b('column')}>
          {`${t.bet_amount}${currency}`}
        </div>
        <div className={b('column')}>
          {`${t.coefficient}x`}
        </div>
        <div className={b('column')}>
          {`${t.win_amount}${currency}`}
        </div>
      </div>
    ))
  ), [b, currency, myBets, icon_id])

  return <section className={b()}>
    <div className={b('header', { lang })}>
      <div className={b('column')}>
        {locale.player.toUpperCase()}
      </div>
      <div className={b('column')}>
        {locale.amount.toUpperCase()}
      </div>
      <div className={b('column')}>
        {locale.odd.toUpperCase()}
      </div>
      <div className={b('column')}>
        {locale.win.toUpperCase()}
      </div>
    </div>
    <div className={b('items')}>
      {resultList}
    </div>
  </section>
}

export default MyBets;