import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Socket, ChatSocket } from 'services/api/Socket';
import queryString from 'query-string';

import { eventActions as authActions } from './features/Auth/redux';
import { eventActions as gameActions } from './features/Game/redux';
import { eventActions as historyActions } from './features/History';
import { eventActions as jackpotActions } from './features/Jackpot';
import { eventActions as chatActions } from './features/Chat';
import { gameError as socketGameErrorActions } from './features/SocketErrorBoundary/redux';
import { chatError as socketChatErrorActions } from './features/SocketErrorBoundary/redux';
import configureStore from './configureStore';
import Api from './services/api';

const lastSubDomain = window.location.host.split('.')?.[0];

const connectStrings = {
  dino: 'dino',
}

const api = new Api(process.env.NODE_ENV === 'production' ? `/api/${connectStrings[lastSubDomain] || 'zeppelin'}` : `/api/${connectStrings[lastSubDomain] || 'zeppelin'}`);

const token = queryString.parse(window.location.search).token;
const lang = queryString.parse(window.location.search).lang;
const homeUrl = queryString.parse(window.location.search).homeUrl;
const socket = new Socket(process.env.NODE_ENV === 'production' ? `wss://${window.location.host}/api/${connectStrings[lastSubDomain] || 'zeppelin'}` : `wss://firecrash.sandbox.fgstatic.xyz/api/${connectStrings[lastSubDomain] || 'zeppelin'}`, token, {
  ...authActions,
  ...gameActions,
  ...historyActions,
  ...jackpotActions,
  ...socketGameErrorActions,
})
const chatSocket = new ChatSocket(process.env.NODE_ENV === 'production' ? `wss://${window.location.host}/api/${connectStrings[lastSubDomain] || 'zeppelin'}/chat` : `wss://firecrash.sandbox.fgstatic.xyz/api/${connectStrings[lastSubDomain] || 'zeppelin'}/chat`, token, {
  ...chatActions,
  ...socketChatErrorActions,
}, true)
const store = configureStore({ api, socket, chatSocket });


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App lang={lang} homeUrl={homeUrl || ''} />
    </BrowserRouter>
  </Provider>, document.getElementById('root'));
