import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import close from 'services/img/close_gr.svg';

import './StatisticComponent.scss';

const b = block('statistic-component');

const StatisticComponent = ({ setOpen }) => {
    const locale = useSelector(state => state.locale.locale);
    const coefsHistory = useSelector(state => state.game.coefsHistory);

    const coefs = useMemo(() => coefsHistory.map(coef => <div
        key={coef.game_id}
        className='bets-coef'
        style={{ color: coef.crash_point <= 1.8 ? '#eb2020' : '#2ecd45' }}
    >{coef.crash_point}</div>), [coefsHistory]);

    const onCloseClick = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <div className={b()}>
            <div className={b('container')}>
                <div className={b('header')}>
                    {locale.roundHistory}
                    <SVGInline svg={close} className={b('svg').toString()} alt='close' onClick={onCloseClick} />
                </div>
                <div className={b('content')}>
                    <div className={b('bets-container')}>
                        {coefs}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatisticComponent;