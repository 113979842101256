import icon_0 from './0.png';
import icon_1 from './1.png';
import icon_2 from './2.png';
import icon_3 from './3.png';
import icon_4 from './4.png';
import icon_5 from './5.png';
import icon_6 from './6.png';
import icon_7 from './7.png';
import icon_8 from './8.png';
import icon_9 from './9.png';
import icon_10 from './10.png';
import icon_11 from './11.png';
import icon_12 from './12.png';
import icon_13 from './13.png';
import icon_14 from './14.png';
import icon_15 from './15.png';
import icon_16 from './16.png';
import icon_17 from './17.png';
import icon_18 from './18.png';
import icon_19 from './19.png';
import icon_20 from './20.png';
import icon_21 from './21.png';

const icons = {
  0: icon_0,
  1: icon_1,
  2: icon_2,
  3: icon_3,
  4: icon_4,
  5: icon_5,
  6: icon_6,
  7: icon_7,
  8: icon_8,
  9: icon_9,
  10: icon_10,
  11: icon_11,
  12: icon_12,
  13: icon_13,
  14: icon_14,
  15: icon_15,
  16: icon_16,
  17: icon_17,
  18: icon_18,
  19: icon_19,
  20: icon_20,
  21: icon_21,
}

const getIconById = id => icons[id?.toString()] || icons[0];

export { getIconById, icons };