import React, { memo, useEffect, useState } from 'react';
import block from 'bem-cn';

import './SpriteBlow.scss';

const b = block('blow');

const frameSize = [400, 350];
const frameMatrix = [9, 5];

const SpriteBlow = () => {
    const [xy, setXY] = useState([0, 0]);

    useEffect(() => {
        let i = 1;

        const intervalId = setInterval(() => {
            if (i >= frameMatrix[0] * frameMatrix[1]) {
                i = 0;
                clearInterval(intervalId);
                return;
            }

            const currentRow = Math.floor(i / frameMatrix[1]);
            const y = currentRow * frameSize[1];

            const currentElement = i % frameMatrix[1];
            const x = currentElement * frameSize[0];

            setXY([x, y]);

            i++;
        }, 50);

        return () => {
            clearInterval(intervalId);
        }
    }, []);

    return (
        <div className={b()}>
            <div className={b('image')} style={{ backgroundPositionX: -xy[0], backgroundPositionY: -xy[1] }} />
        </div>
    );
};

export default memo(SpriteBlow);