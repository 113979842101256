import React, { useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector } from 'react-redux';

import downArrow from './img/down_arrow.svg';
import upArrow from './img/up_arrow.svg';
import { EditUser } from 'features/Auth';

import { getIconById } from './img/icons';

import './Header.scss';

const Header = () => {
  const b = block('header');
  const currency = useSelector(state => state.auth.currency);
  const balance = useSelector(state => state.auth.balance);
  const nick = useSelector(state => state.auth.nick);
  const icon_id = useSelector(state => state.auth.icon_id);
  const { locale } = useSelector(state => state.locale);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  return <>
    {window.innerWidth > 530 ? <header className={b()} onClick={() => setIsOpenEdit(!isOpenEdit)}>
      <div className={b('user-block')}>
        <div className={b('user-icon')}>
          <img src={getIconById(icon_id)} className={b('user-svg')} alt='icon' loading='lazy' />
        </div>
        <div className={b('user-nick')}>{nick || 'noname'}</div>
        <div className={b('settings-icon')}>
          {isOpenEdit
            ? <SVGInline svg={downArrow} className={b('pencil').toString()} onClick={() => setIsOpenEdit(!isOpenEdit)} />
            : <SVGInline svg={upArrow} className={b('pencil').toString()} onClick={() => setIsOpenEdit(!isOpenEdit)} />}
        </div>
      </div>
      <div className={b('user-info')}>
        {`${(locale.balance).toUpperCase()} `}
        <div className={b('balance')}>
          {`${(+balance).toFixed(2)} ${currency}`}
        </div>
      </div>
    </header> :
      <header className={b()} onClick={() => setIsOpenEdit(!isOpenEdit)}>
        <div className={b('user-block')}>
          <div className={b('user-icon')}>
            <img src={getIconById(icon_id)} className={b('user-svg')} alt='icon' loading='lazy' />
          </div>
          <div className={b('user-container')}>
            <div className={b('nick-icon-container')}>
              <div className={b('user-nick')}>{nick || 'noname'}</div>
              <div className={b('settings-icon')}>
                {isOpenEdit
                  ? <SVGInline svg={downArrow} className={b('pencil').toString()} onClick={() => setIsOpenEdit(!isOpenEdit)} />
                  : <SVGInline svg={upArrow} className={b('pencil').toString()} onClick={() => setIsOpenEdit(!isOpenEdit)} />}
              </div>
            </div>
            <div className={b('user-info')}>
              {`${(locale.balance).toUpperCase()} `}
              <div className={b('balance')}>
                {`${(+balance).toFixed(2)} ${currency}`}
              </div>
            </div>
          </div>
        </div>
      </header>}
    {isOpenEdit && <EditUser setIsOpenEdit={setIsOpenEdit} />}
  </>
}

export default Header;