import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import styles from '../../../data';
import SpriteBlow from '../../../data/default/SpriteBlow';
import zep from '../../../data/default/object.svg';


import './Firework.scss';

const b = block('firework');

const Firework = ({ isPause, isAnimations }) => {
  const periodType = useSelector(state => state.game.periodType);
  const styleCode = useSelector(state => state.game.styleCode);
  
  return <React.Fragment>
    <div className={b('animation-container', {
      isAnimation: !isPause,
      isHorizon: styles[styleCode].isHorizon,
      isPauseAnimation: periodType === 2 && !isPause
    })}>
      <div className={b({
        isHorizon: styles[styleCode].isHorizon,
        notNeedAfterAnimation: styles[styleCode].notNeedAfterAnimation,
        isEqualMarginAndHeight: styles[styleCode].isEqualMarginAndHeight,
        notNeedCoefTop: styles[styleCode].notNeedCoefTop,
      })}>
        {periodType === 1 
        ? <SVGInline svg={zep} className={b('rocket', { isPauseAnimation: periodType === 2 && !isPause, hideAnimation: isAnimations }).toString()} /> 
        :<div className={b('blow')}> <SpriteBlow /></div>}
      </div>
    </div>
  </React.Fragment>;
}

export default Firework;