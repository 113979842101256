import { rules } from './rules';

export const locale = {
  // Advice
  editAdvice: 'You can set a nickname and avatar by clicking on the pencil',

  //General
  id: 'Id',
  ok: 'Ok',
  amount: 'Bet',

  //Main
  maxWin: 'Maximum win',
  playNow: 'Play now',
  topWins: 'Statistics',
  roundID: 'Round ID',
  balance: 'Bal.',
  play: 'Play',
  history: 'Bets',
  chat: 'Chat',


  //Game
  min: 'Min',
  max: 'Max',
  placeBet: 'Place your bet',
  bet: 'Bet',
  noName: 'No name',
  nextRound: 'Place your bets please',
  coef: 'Coefficient!',
  winPopup: 'Win:',

  //History
  bets: 'All Bets',
  myBets: 'My bets',
  results: 'Results',
  betAmount: 'Bet amount',
  youWon: 'Victory! Your balance:',
  odd: 'CASHOUT',
  win: 'Win',
  player: 'User',
  historyRoundID: 'Round ID',
  historyBetAmount: 'Bet amount',
  historyOdd: 'Odds',
  historyWinAmount: 'Win Amount',

  //Auth
  userUpdateSuccess: 'Data updated!',
  userUpdateFail: 'An error has occurred. Please try a different nickname or update the data later.',

  //Chat
  online: 'Online',
  enterMessage: 'Enter your message here',
  openChat: 'Open chat',
  closeChat: 'Close chat',
  drop: 'Drop',
  smile: 'Smileys & People',
  recent: 'Recent',
  activity: 'Activity',

  //Basket
  autoCashout: 'Auto cashout',
  autoBet: 'Auto bet',
  allIn: 'All-In',
  placedBet: 'Bet placed',
  cashout: 'Cashout',
  cancelBet: 'Cancel',
  canceledBet: 'Your bet has been successfully canceled',
  betLimit: (amount, currency) => `Your bet for this round should not exceed ${amount} ${currency}`,
  autoBetActivation: 'ACTIVATION:',
  nextBet: 'For next round',

  // Settings
  sound: 'Sound',
  music: 'Sound/Music',
  lang: 'CHOOSE LANGUAGE',

  // Jackpot
  won: 'won',
  anotherWinnerNotify: (winner_name, jackpot_name, winner_amount, currency) => `Player ${winner_name} won a ${jackpot_name} of ${winner_amount} ${currency}`,
  congratulations: 'Congratulations',
  youWonJackpot: `You win:`,
  jackpotTextO2: 'The jackpot "O2" is played at a multiplier from 500x till 900x.',
  jackpotTextHe: 'The jackpot "He" is played at a multiplier from 900x and higher.',
  jackpotText_3: 'Bets which were cashed out till 2x multiplier will not receive winning from the jackpot.',
  jackpotText_2: 'The jackpot winning amount will be split proportional to the bets.',
  jackpot: 'Jackpot',
  closeJackpot: 'Close',

  //freebet 
  freeBets: 'FREE BETS',
  noFreeBet: 'No Active Free Bets',

  //Statistic 
  roundHistory: 'ROUND HISTORY',

  //Footer 
  rights: 'ALL RIGHTS RESERVED © 2023',
  poweredBy: '© POWERED BY BETSOLUTIONS',

  //Rules
  rules: 'ZEPPELIN',
  howToPlay: 'How to play?',
  detailedRules: 'Detailed rules',
  rule1: 'BET before the start of the round',
  rule2: 'WATCH as Crash grow and your winnings increase',
  rule3: 'CASHOUT before Crash disappears and win X more!',
  rulesHtml: rules,

  //MegaWins 
  megaWinMega: 'Mega',
  megaWinWin: 'Win',
}