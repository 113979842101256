import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { actions as gameActions } from 'features/Game';

import close from './img/close.svg';

import './WinningPopup.scss';

const b = block('winning-popup');

const WinningPopup = () => {
    const locale = useSelector(state => state.locale.locale);
    const { winCoef, winAmount } = useSelector(state => state.game);
    const currency = useSelector(state => state.auth.currency);

    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        dispatch(gameActions.closeWinPopup());
    }, [dispatch]);

    return (
        <div className={b()}>
            <div className={b('container')}>
                <div className={b('item')}>
                    {locale.coef}
                    <div className={b('text')}>{`${winCoef}x`}</div>
                </div>
                <div className={b('item', { win: true })}>
                    {locale.winPopup}
                    <div className={b('text')}>{`${winAmount}${currency}`}</div>
                </div>
            </div>
            <div className={b('button')} onClick={handleClose}>
                <SVGInline svg={close} className={b('icon').toString()} />
            </div>
        </div>
    );
};

export default WinningPopup;