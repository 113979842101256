import React from 'react';
import { useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import block from 'bem-cn';
import { Tooltip } from 'react-tooltip';
import { useUniqueId } from 'services/hooks/useUniqueId';

import JackpotCell from './JackpotCell';

import './JackpotItem.scss';
import 'react-tooltip/dist/react-tooltip.css'

const b = block('jackpot-item');

const JackpotItem = ({ jackpot, color, icon, cellColor, text }) => {
  const tooltipAnchor = useUniqueId('jackpot-item');

  const currency = useSelector(state => state.auth.currency);
  const locale = useSelector(state => state.locale.locale);
  const amount = Array.from(String((jackpot.amount?.[currency]).toFixed(0)).padStart(6, '0'));

  return (
    <>
      <section id={tooltipAnchor} className={b()} style={{ background: color }}>
        <div className={b('icon')}>
          <SVGInline svg={icon} className={b('svg').toString()} />
        </div>
        <div className={b('amount')}>
          {amount.map((item, i) => (
            <JackpotCell key={i} value={item} cellColor={cellColor} />
          ))}
        </div>
        <div className={b('currency')}>
          {currency}
        </div>
      </section>
      <Tooltip anchorSelect={`#${tooltipAnchor}`} place="bottom" style={{ 'background': color, zIndex: 100000, padding: '20px', boxShadow: '0 5px 15px rgba(0, 0, 0, .5)', width: '25%' }}>
        <div style={{background: color, fontSize: 14, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', rowGap: '5px'}}>
          <div>{text}</div>
          <div>{locale.jackpotText_2}</div>
          <div>{locale.jackpotText_3}</div>
        </div>
      </Tooltip>
    </>
  )
}

export default JackpotItem;
