import GameApi from './GameApi';
import UserApi from './UserApi';
import HistoryApi from './HistoryApi';

class Api {
  constructor(baseUrl = '') {
    this.game = new GameApi(baseUrl);
    this.user = new UserApi(baseUrl);
    this.history = new HistoryApi(baseUrl);
  }
}

export default Api;
