import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import JackpotItem from './JackpotItem';
import JackpotWindow from './JackpotWindow';

import o2 from './img/o2.svg';
import he from './img/he.svg';

import { actions } from '../';

import './Jackpot.scss';

const b = block('jackpot');

const Jackpot = () => {
  const dispatch = useDispatch();
  const { jackpots } = useSelector(state => state.jackpot);
  const winner = useSelector(state => state.jackpot.winners[0]);
  const locale = useSelector(state => state.locale.locale);
  const jackpotStyles = [
    {
      color: '#243558',
      cellColor: '#1270a1',
      icon: o2,
      text: locale.jackpotTextO2,
      headerColor: '#1F3052',
    },
    {
      color: '#542161',
      cellColor: '#a129c9',
      icon: he,
      text: locale.jackpotTextHe,
      headerColor: '#2f1c37',
    }
  ];

  const closeWindow = useCallback(() => {
    dispatch(actions.removeWinner())
  }, [dispatch])
  
  const items = useMemo(() => jackpots.map((t, i) => <div key={t.id} className={b('item')}>
      <JackpotItem 
        jackpot={t} 
        color={jackpotStyles[i].color || jackpotStyles[0].color} 
        icon={jackpotStyles[i].icon || jackpotStyles[0].icon} 
        cellColor={jackpotStyles[i].cellColor || jackpotStyles[0].cellColor}
        text={jackpotStyles[i].text || jackpotStyles[0].text}
      />
    </div>), [jackpots, jackpotStyles])

  return <article className={b()}>
    {items}
    {winner ? <JackpotWindow 
    jackpot={winner} 
    closeWindow={closeWindow} 
    bodyColor={winner.jackpot_name === 'Premium,jackpot' ? jackpotStyles[0].color : jackpotStyles[1].color}
    headerColor={winner.jackpot_name === 'Premium,jackpot' ? jackpotStyles[0].headerColor : jackpotStyles[1].headerColor}
    cellColor={winner.jackpot_name === 'Premium,jackpot' ? jackpotStyles[0].cellColor : jackpotStyles[1].cellColor} 
    headerIcon={winner.jackpot_name === 'Premium,jackpot' ? jackpotStyles[0].icon : jackpotStyles[1].icon}
    /> : null}
  </article>
}

export default Jackpot;