import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import EmojiPicker from 'emoji-picker-react';

import { actions } from '../';

import Input from 'components/Input';
import Messages from './Messages';
import { SocketErrorBoundary } from 'features/SocketErrorBoundary';

import ellipseSvg from './img/orange-ellipse.svg';
import sendSvg from './img/plane.svg';
import smile from './img/smile.svg';
import drop from './img/drop.svg';

import './Chat.scss';

const b = block('chat');

const Chat = ({ scrollRef }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const online = useSelector(state => state.chat.online);
  const locale = useSelector(state => state.locale.locale);
  const { isChatSocketError, chatSocketError } = useSelector(state => state.socketError);
  const [isEmogiOpen, setIsEmogiOpen] = useState(false);
  const category = [
    {
      category: 'suggested',
      name: locale.recent,
    },
    {
      category: 'smileys_people',
      name: locale.smile,
    },
    {
      category: 'activities',
      name: locale.activity,
    }
  ];

  const onSmileClick = useCallback(() => {
    setIsEmogiOpen(prev => !prev);
  }, []);

  const textValidator = useCallback(value => setText(value.substring(0, 250)), []);

  const onEmojiClick = useCallback((value) => {
    textValidator(`${text}${value.emoji}`);
  }, [text, textValidator]);

  const sendMessage = useCallback(() => {
    if (text.length > 0) dispatch(actions.sendMessage(text, () => setText('')))
  }, [dispatch, text])

  const onKeyPressClick = useCallback((e) => {
    if (e.which === 13) {
      if (text.length > 0) dispatch(actions.sendMessage(text, () => setText('')))
    }
  }, [dispatch, text]);

  return isChatSocketError ? <SocketErrorBoundary error={chatSocketError} /> : <article className={b()}>
    <div className={b('top-block')}>
      <SVGInline svg={ellipseSvg} className={b('ellipse').toString()} />
      {`${locale.online} (${(online || 0) + 4})`}
    </div>
    <div className={b('messages')}>
      <Messages />
    </div>
    <div className={b('input-container')}>
      <Input
        type="textarea"
        value={text}
        placeholder={`${locale.enterMessage}...`}
        callBack={textValidator}
        icon={sendSvg}
        iconCallback={sendMessage}
        onKeyPress={onKeyPressClick}
      />
    </div>
    <div className={b('bottom-block')}>
      <div className={b('icon-container')}>
        <SVGInline svg={smile} className={b('send').toString()} alt='smile' onClick={onSmileClick} />
      </div>
      {isEmogiOpen && <EmojiPicker
        categories={category}
        size='24'
        height={360}
        width={338}
        onEmojiClick={onEmojiClick}
      />}
      <div className={b('gif')}>GIF</div>
      <div className={b('drop')}>
        <div className={b('drop-icon-container')}>
          <SVGInline svg={drop} alt='drop' className={b('drop-icon').toString()} />
        </div>
        <div className={b('text-drop')}>{locale.drop}</div>
      </div>
      <div className={b('counter')}>{250 - text.length}</div>
    </div>
  </article>
}

export default Chat;