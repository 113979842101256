import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import close from 'services/img/close_gr.svg';
import tickets from '../img/tickets2.svg';

import './TicketsComponent.scss';

const b = block('tickets-component');

const TicketsComponent = ({ onClose, isOpen }) => {
    const locale = useSelector(state => state.locale.locale);

    const [isClosing, setIsClosing] = useState(false);

    const handleClose = useCallback(() => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
        }, 300);
    }, [onClose]);

    if (!isOpen) {
        return null;
    }

    return (
        <div className={b()}>
            <div className={b('container', { isClose: isClosing })}>
                <div className={b('header')}>
                    <div>{locale.freeBets}</div>
                    <SVGInline svg={close} className={b('svg').toString()} alt='close' onClick={handleClose} />
                </div>
                <div className={b('content')}>
                    <div className={b('tickets')}>
                        <SVGInline svg={tickets} className={b('icon').toString()} alt='tickets' />
                    </div>
                    <div className={b('text')}>
                        {locale.noFreeBet}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketsComponent;