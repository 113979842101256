import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import { getIconById } from 'components/Header/img/icons';

import './Messages.scss';

const b = block('messages');

const Messages = () => {
  const isOpen = useState(false);
  const messages = useSelector(state => state.chat.messages);
  const userID= useSelector(state => state.auth.userID);

  const messagesList = useMemo(() => [...messages].map(t => <div key={t.time + t.userID} className={b('item', { isAlien: userID !== t.userID })}>
    <img src={getIconById(t.icon_id)} className={b('icon')} loading='lazy' alt='icon' />
    <div className={b('item-text', { isAlien: userID !== t.userID })}>
      <div className={b('nick')}>
        {`${t.nick?.length > 0 ? t.nick : t.userID}`}
      </div>
      <div className={b('text', { isAlien: userID !== t.userID })}>
        {t.message}
      </div>
    </div>
  </div>), [messages, userID])
  
  return <div className={b({ isOpen })}>
    {messagesList}
  </div>
}

export default Messages;