import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions } from 'features/Game';

import SwitchBox from 'components/SwitchBox';
import Input from 'components/Input';
import Button from 'components/Button';

import './BasketItem.scss';

const b = block('basket-item');

const BasketItem = ({
    isAlone,
    isProcess,
    periodType,
    index,
    button,
}) => {
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale.locale);
    const currency = useSelector(state => state.auth.currency);
    const balance = useSelector(state => state.auth.balance);
    const tempCoef = useSelector(state => state.game.coef);
    const limits = useSelector(state => state.game.limits);
    const [isAutoBet, setAutoBet] = useState(false);
    const [isPlaced, setPlaced] = useState(false);
    const tempBet = useSelector(state => state.game.cashedBets)?.find(t => t.bet_id === isPlaced);
    const [isAutoCashout, setAutoCashout] = useState(false);
    const [amount, setAmount] = useState(limits.min);
    const [isBlock, setBlock] = useState(false);
    const [coef, setCoef] = useState(5);
    const [isNextBet, setNextBet] = useState(false);

    // Place bet function
    const placeBet = useCallback(() => {
        dispatch(actions.placeBet(+amount, +coef, isAutoCashout, setPlaced, isAutoBet, index));
    },
        [amount, coef, dispatch, isAutoBet, isAutoCashout, index]);

    // Auto bet
    useEffect(() => {
        if (periodType === 2) setPlaced(false);
        if (periodType === 2 && isAutoBet) {
            placeBet();
        }
        if (periodType === 2 && isNextBet) {
            setNextBet(false);
            placeBet();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodType])

    // Cashout money function
    const cashoutBet = useCallback(() => {
        dispatch(actions.cashoutBet(isPlaced, setPlaced))
    }, [dispatch, isPlaced]);

    const cancelBet = useCallback(() => {
        if (isNextBet && periodType === 1) {
            setNextBet(false);
        } else {
            dispatch(actions.cancelBet(isPlaced, setPlaced))
        }
    }, [dispatch, isPlaced, isNextBet, periodType])

    // Reset bet
    useEffect(() => {
        if (tempBet && isPlaced) {
            setPlaced(false)
        }
    }, [isPlaced, tempBet])

    // Set block
    useEffect(() => {
        if (isPlaced || isProcess || isNextBet) {
            setBlock(true)
        } else {
            setBlock(false)
        }
    }, [isPlaced, isProcess, isNextBet])

    // Set limits
    useEffect(() => setAmount(limits.min), [limits.min])

    // For validate after blur coef
    const validateBlurCoef = value => {
        if (isAutoCashout) {
            if (+value > 1.1) {
                setCoef(+(+value).toFixed(2))
            } else {
                setCoef(1.01)
            }
        }
    }

    // For validate amount
    const validateChangeAmount = useCallback(value => {
        if (!isBlock) {
            if (value > limits.max) setAmount(+limits.max.toFixed(1))
            else if (value < limits.min) setAmount(+limits.min.toFixed(1))
            else if (coef * value > limits.win) setAmount(+(limits.win / coef).toFixed(1))
            else if (+(+value).toFixed(1) > balance) {
                if (balance < limits.min) {
                    setAmount(+limits.min.toFixed(1))
                } else {
                    setAmount(Math.floor(balance * 10, 1) / 10)
                }
            }
            else setAmount(+(+value).toFixed(1));
        }
    }, [balance, coef, isBlock, limits.max, limits.min, limits.win])

    // For validate input Amount
    const validateTempAmount = useCallback(value => {
        if (!isBlock) {
            if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
                value.toString().split('').filter(t => t === '.').length < 2) {
                if (value.toString().split('')[0] === '.') {
                    setAmount(value.substring(1, value.length))
                } else setAmount(value)
            }
        }
    }, [isBlock])


    // For validate input coef
    const validateChangeCoef = value => {
        if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
            value.toString().split('').filter(t => t === '.').length < 2 && isAutoCashout) {
            if (value < 1.01 && value !== '' && +value !== 1) setCoef(1.01)
            else if (amount * value > limits.win) setCoef(limits.win / amount)
            else setCoef(value);
        }
    }

    const buttonState = useMemo(() => {
        if (isPlaced) {
            return periodType === 1 ? 'cashout' : 'cancel-bet';
        } else {
            if (periodType !== 1) {
                return 'place-bet';
            } else if (isNextBet) {
                return 'cancel-bet'
            } else {
                return 'place-next-bet'
            }
        }
    }, [isPlaced, periodType, isNextBet]);

    const tempButtonOptionsMap = useMemo(() => ({
        'cashout': {
            text: (
                <div className={b('text-button-container')}>
                    <div>{locale.cashout}</div> 
                    <div className={b('button-title')}>{`${(tempCoef * amount).toFixed(2)} ${currency}`}</div>
                </div>
            ),
            color: 'yellow',
            callBack: cashoutBet,
            disabled: false,
        },
        'cancel-bet': {
            text: <div className={b('button-title')}>{locale.cancelBet}</div>,
            color: 'red',
            callBack: cancelBet,
            disabled: true,
        },
        'place-bet': {
            text: <div className={b('button-title')}>{locale.placeBet}</div>,
            color: 'green',
            callBack: placeBet,
            disabled: false,
        },
        'place-next-bet': {
            text: <div className={b('text-button-container')}><div className={b('button-title')}>{locale.placeBet}</div><div>{locale.nextBet}</div></div>,
            color: 'green',
            callBack: () => setNextBet(true),
            disabled: false,
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [locale, tempCoef, amount, currency]);

    const tempButtonOptions = tempButtonOptionsMap[buttonState];
    
    return (
        <div className={b()}>
            <div className={b('bets-container', { isAlone })}>
                <div className={b('left', { isAlone })}>
                    <div className={b('left-content')}>
                        <div className={b('content-container')}>
                            <div className={b('label')}>{`${locale.bet}:`}</div>
                            <div className={b('row')}>
                                <div className={b('change-container')}>
                                    <div className={b('change-block')}>
                                        <Input
                                            value={amount}
                                            onChange={e => validateTempAmount(e.currentTarget.value)}
                                            onBlur={() => validateChangeAmount(amount)}
                                            unBorder
                                            centerText />
                                    </div>
                                    <div className={b('change-currency')} >
                                        {currency}
                                    </div>
                                </div>
                            </div>
                            <div className={b('row')}>
                                <span className={b('text')}>{`${locale.autoBet}:`}</span>
                                <SwitchBox value={isAutoBet} changeValue={value => setAutoBet(value)} />
                            </div>
                        </div>
                        <div className={b('content-container')}>
                            <div className={b('label')}>{`${locale.autoCashout}:`}</div>
                            <div className={b('row')}>
                                <div className={b('change-container')}>
                                    <div className={b('change-block', { isBlock: !isAutoCashout })}>
                                        <Input
                                            value={coef}
                                            onChange={e => validateChangeCoef(e.currentTarget.value)}
                                            onBlur={() => validateBlurCoef(coef)}
                                            unBorder
                                            centerText />
                                    </div>
                                    <div className={b('change-currency')} >
                                        {'X'}
                                    </div>
                                </div>
                            </div>
                            <div className={b('row')}>
                                <span className={b('text')}>{locale.autoBetActivation}</span>
                                <SwitchBox value={isAutoCashout} changeValue={value => isBlock ? isBlock : setAutoCashout(value)} />
                            </div>
                        </div>

                    </div>
                    {window.innerWidth > 640 ? <div className={b('bet-multipliers-container')}>
                        <div className={b('bet-block', { size: 'big' })} onClick={() => validateChangeAmount(amount * 2)}>
                            x2
                        </div>
                        <div className={b('bet-block', { size: 'big' })} onClick={() => validateChangeAmount(amount * 4)}>
                            x4
                        </div>
                        <div className={b('bet-block', { size: 'big' })} onClick={() => validateChangeAmount(amount * 8)}>
                            x8
                        </div>
                        <div className={b('bet-block', { size: 'big' })} onClick={() => validateChangeAmount(balance)}>
                            {`${locale.max}.`}
                        </div>
                    </div> : null}
                </div>
                <div className={b('right', { isAlone })}>
                    <div className={b('row', { size: 'big' })}>
                        <Button
                            text={tempButtonOptions.text}
                            size='container'
                            isUppercase
                            isDisabled={isProcess || (isNextBet && periodType === 1) || tempButtonOptions.disabled}
                            color={tempButtonOptions.color}
                            callBack={() => {
                                tempButtonOptions.callBack();
                                dispatch(actions.buttonClick());
                            }}
                        />
                    </div>
                </div>
            </div>
            {window.innerWidth > 640 ? button : null}
        </div>
    );
};

export default BasketItem;