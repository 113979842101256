import React, { useMemo, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import { getIconById } from 'components/Header/img/icons';
import { actions } from '../';

import './TopWins.scss';

const TopWins = () => {
  const dispatch = useDispatch();
  const b = block('top-wins');
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang);
  const currency = useSelector(state => state.auth.currency);
  const { topWins } = useSelector(state => state.history);

  useEffect(() => {
    dispatch(actions.getTopWins());
  }, [dispatch])

  const topWinsList = useMemo(() => (
    topWins.map((t, i) => (
      <div key={`${t.value}${t.round_id}${i}`} className={b('item')}>
        <div className={b('column-icon')}>
          <img src={getIconById(t.icon_id)} className={b('icon')} loading='lazy' alt='icon' />
          {t.nick || t.user_id}
        </div>
        <div className={b('column')}>
          {`${t.bet_amount}${currency}`}
        </div>
        <div className={b('column')}>
          {`${t.coefficient}x`}
        </div>
        <div className={b('column')}>
          {`${t.win_amount}${currency}`}
        </div>
      </div>
    ))
  ), [b, topWins, currency])

  return <section className={b()}>
    <div className={b('header', { lang })}>
      <div className={b('column')}>
        {locale.player.toUpperCase()}
      </div>
      <div className={b('column')}>
        {locale.amount.toUpperCase()}
      </div>
      <div className={b('column')}>
        {locale.odd.toUpperCase()}
      </div>
      <div className={b('column')}>
        {locale.win.toUpperCase()}
      </div>
    </div>
    <div className={b('items')}>
      {topWinsList}
    </div>
  </section>
}

export default TopWins;