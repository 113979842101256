import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import zippelin from './img/loader_img.svg';

import './Pause.scss';

const b = block('pause');

const Pause = () => {
  const { restartDuration } = useSelector(state => state.game)
  const { locale } = useSelector(state => state.locale);

  return <article className={b()}>
    <div className={b('zeppelin')}>
      <SVGInline svg={zippelin} alt='zippelin' className={b('icon').toString()} />
    </div>
    <div className={b('animation')}>
      <div className={b('progress')} style={{animationDuration: `${(restartDuration - 2250) / 1000}s`}}></div>
    </div>
    {locale.nextRound}
  </article>
}

export default Pause;