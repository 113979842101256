import React from 'react';
import block from 'bem-cn';

import './DotsAnimation.scss';

const b = block('animation-container');

const DotsAnimation = ({isVertical}) => {
    return (
        <div className={b({isVertical})}>
            <div className={b('horizont-shadow', {isVertical})}></div>
            <div className={b('to-left-animation', {isVertical})}></div>
        </div>
    );
};

export default DotsAnimation;