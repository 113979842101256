import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import { languageIcons } from './img';
import { actions } from './';
import { changeLang } from 'features/locale/redux/actions';

import SwitchBox from 'components/SwitchBox';

import './Settings.scss';

const b = block('settings');

const Settings = ({ isOpen }) => {
  const dispatch = useDispatch();
  const { isMusic } = useSelector(state => state.settings);
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang);

  const switchMusic = useCallback(value => dispatch(actions.setMusic(value)), [dispatch]);

  const changeLangOnClick = useCallback(lang => {
    dispatch(changeLang(lang))
  }, [dispatch]);

  return <article className={b({ isOpen })}>
    <div className={b('row')}>
      <span className={b('text')}>{locale.lang}</span>
      <div className={b('languages_container')}>
        {Object.entries(languageIcons).map(item => <SVGInline 
        key={item[0]} 
        svg={item[1]} 
        className={b('lang-icon', {activeLang: lang === item[0]}).toString()} 
        onClick={ () => changeLangOnClick(item[0])}
      />)}
      </div>
    </div>
    <div className={b('row')}>
      <span className={b('text')}>{locale.music}</span>
      <SwitchBox value={isMusic} changeValue={value => switchMusic(value)} />
    </div>
  </article>
}

export default Settings;
