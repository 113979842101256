import bets from './img/bets.svg';
import betsY from './img/bets_y.svg';
import chatY from './img/chat_y.svg';
import chat from './img/chat.svg';
import playY from './img/play_y.svg';
import play from './img/play.svg'; 

import * as defaultStyles from './default';

const styles = {
  default: {
    object: defaultStyles.object,
  },
}

export const categories = {
  play: {
    id: 0,
    textIdent: 'play',
    icon: play,
    choosenIcon: playY,

  },
  history: {
    id: 1,
    textIdent: 'history',
    icon: bets,
    choosenIcon: betsY,
  },
  chat: {
    id: 2,
    textIdent: 'chat',
    icon: chat,
    choosenIcon: chatY,
  },
}

export default styles;