export const rules = (amountMax, currency) => `<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial; color: rgb(255, 255, 255); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
Rules:
</span></p>
<ul>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
Before each round the player can place the desired bet.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
After placing a bet, on the screen will be graphically displayed the coefficient which rises to some randomly chosen point.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
Winning is determined by multiplying the bet amount and the coefficient at the current moment.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
In the "Auto Cashout" field you can specify the maximum coefficient after which you wish to receive winning automatically.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
In case of using a Cashout button the winning amount is determined by coefficient in the moment of pressing the button.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
The money that player won will be reflected on the balance before the next round.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
Jackpot - The jackpot "O2" is played at a multiplier from 500x till 900x. The jackpot "He" is played at a multiplier from 900x and higher. The jackpot winning amount will be split proportional to the bets. Bets which were cashed out till 2x multiplier will not receive winning from the jackpot.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
Free Bet - Free Bet can be used to make bet on desired round without spending players balance. Each Free Bet has bet value and min. multiplier to cashout. Cashing out on smaller multiplier is not allowed by system and cashout button will be active for player when multiplier will exceed specific Free Bet's multiplier. Making cash bet and Free Bet same time is not allowed. Making bets with two or more Free Bets same time is not allowed. Once Free Bet is used player can not cancel it.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
Drop - This feature will give selected amount to random online users. User should choose desired amount and quantity of players to drop money to. If there is less online players then user has chosen, the system will adjust amount accordingly and will pay only to online users.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
Coefficient rising may be discontinued at any time (including on 1.00). The bet is considered as lost if the coefficient rise will be stopped before player hits Cashout button. Coefficient range is from 1.00 till infinity.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
Maximum profit at bet is 30 000 (in currency shown in the game).
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
If the coefficient exceeds the number chosen in Auto Cashout field, then the user will win the amount which depends on the coefficient chosen in Auto Cashout field.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 30pt; list-style: disc;">
Checking round fairness: 
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 60pt; list-style: circle;">
Round checking allows you to make sure that the Coefficient of the round is predefined and does not depend on users' bet or other factors.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 60pt; list-style: circle;">
On the server is defined result of upcoming rounds and the game process follows the sequence. The results of the next round are not defined by the current round, which excludes the assumption that the Coefficient of the next round is calculated by the user's behavior.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 60pt; list-style: circle;">
What is the round key and reliability code? Each round consists of 3 basic parameters, which creates the round key. These parameters are: round number, round Coefficient and round unique serial number. The key of the round is the text combination of these three parameters. For example, if the round number is 70588, the round Coefficient is 7.09 and the round unique serial number is 0f51c914-0045-46bc-8487-03036c467cbb, than the round key will have the following format: 70588_7.09_0f51c914-0045-46bc-8487-03036c467cbb
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 60pt; list-style: circle;">
On the game page, under "History" tab is provided list of completed and current rounds. For the finished rounds you can find round key as well as the reliability code. For the current round only the reliability code is shown. The reliability code is the round key encrypted with the SHA256 algorithm. The key encrypted with such algorithm is unique and it is impossible for 2 different keys to have the same encryption code. So if the player can see and compare this code, it is impossible to change the result of the round without changing key.
</li>
<li style="margin-top: 5pt; margin-bottom: 5pt; margin-left: 60pt; list-style: circle;">
How to check the fairness of the round? For example the current round reliability code is: d7b109260bb9ebab3c5b256ad5e4 You should wait for the round key which appears after round is finished. Then you can encrypt that round key with SHA256 algorithm and you will get the same reliability code which was visible for current round. In order to simplify this process, you can use online SHA256 calculators, eg http://www.xorbin.com/tools/sha256-hash-calculator and http://www.md5calc.com/sha256
</li>
</ul>
<p><br></p>`