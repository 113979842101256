import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import BasketItem from './BasketItem';
import plusSvg from './img/plus.svg';
import remove from './img/remove.svg';

import './Basket.scss';

const b = block('basket');

const Basket = () => {
  const lang = useSelector(state => state.locale.lang);
  const periodType = useSelector(state => state.game.periodType);
  const isProcess = useSelector(state => state.game.isProcess);
  const [baskets, setBaskets] = useState([true])

  const removeBasket = useCallback((i) => {
    setBaskets(baskets.filter((_, index) => index !== i));
  }, [baskets]);

  const addBasket = useCallback(() => {
    setBaskets([...baskets, true]);
  }, [baskets]);

  const isAlone = baskets.filter(t => t === true).length === 1;

  // Not one basket
  const isDouble = useMemo(() => !isAlone, [isAlone]);

  return (
    <article className={b({ isDouble, lang })}>
      {baskets.map((t, i) => (
        <BasketItem
          key={i}
          index={i}
          isAlone={isAlone}
          isProcess={isProcess}
          periodType={periodType}
          button={i === 0
            ? isAlone ? (
              <div className={b('more-basket')} onClick={addBasket}>
                <SVGInline svg={plusSvg} className={b('more-plus').toString()} />
              </div>
            ) : null
            : (
              <div className={b('more-basket')} onClick={() => removeBasket(i)}>
                <SVGInline svg={remove} className={b('remove-icon').toString()} />
              </div>
            )
          }
        />
      ))}
    </article>
  );
};

export default Basket;
