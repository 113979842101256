const path = '/ws';

const addActionHandlers = ({ event, actions, dispatch, token, socket }) => {
  const eventData = JSON.parse(event.data);

  const action = actions[eventData.type];
  if (action !== undefined) {
    dispatch(action(eventData.data, token, socket));
  } else {
    console.log(event.type)
  }
};

const addHandlers = ({ socket, actions, dispatch, token }) => {
  socket.onmessage = (event) => {
    addActionHandlers({
      event,
      actions,
      dispatch,
      token,
      socket,
    });
  }
}

class Socket {
  constructor(url, token, actions) {
    this.url = url;
    this.token = token;
    this.actions = actions;
    this.errorAmount = 0;
    this.isSesssionAlive = Boolean(token);

    this.createSocket();
  }

  createSocket = () => {
    if (!this.isSesssionAlive || !this.token) {
      return;
    }

    this.socket = new WebSocket(`${this.url}${path}/?token=${this.token}`);
    this.socket.onclose = () => setTimeout(() => {
      if (this.errorAmount < 3) {
        this.errorAmount++;
      } else this.errorAmount = 0;
      clearInterval(this.interval)
      this.createSocket();
    }, this.errorAmount < 3 ? 5000 : 20000);
    this.socket.onopen = () => {
      this.interval = setInterval(() => this.socket?.send(JSON.stringify({ type: 'ping' })), 30000);
    };
    if (this.dispatch !== undefined) {
      addHandlers({ socket: this.socket, actions: this.actions, dispatch: this.dispatch, token: this.token, handleSessionClose: this.handleSessionClose });
    }
  }

  addSocketHandlers = dispatch => {
    this.dispatch = dispatch;
    addHandlers({ socket: this.socket, actions: this.actions, dispatch: this.dispatch, token: this.token, handleSessionClose: this.handleSessionClose });
  }

  handleSessionClose = () => {
    this.isSesssionAlive = false;
  }

  getSocket = () => this.socket;
}

class ChatSocket {
  constructor(url, token, actions) {
    this.url = url;
    this.token = token;
    this.actions = actions;
    this.isSesssionAlive = Boolean(token);
    this.errorAmount = 0;

    this.createSocket();
  }

  createSocket = () => {
    if (!this.isSesssionAlive || !this.token) {
      return;
    }

    this.socket = new WebSocket(`${this.url}/?token=${this.token}`);
    this.socket.onclose = () => setTimeout(() => {
      if (this.errorAmount < 5) {
        this.errorAmount++;
      } else this.errorAmount = 0;
      clearInterval(this.interval)
      this.createSocket();
    }, 60000);
    this.socket.onopen = () => {
      this.interval = setInterval(() => this.socket?.send(JSON.stringify({ type: 'ping' })), 30000);
    }
    if (this.dispatch !== undefined) {
      addHandlers({ socket: this.socket, actions: this.actions, dispatch: this.dispatch, token: this.token, handleSessionClose: this.handleSessionClose });
    }
  }

  addSocketHandlers = dispatch => {
    this.dispatch = dispatch;
    addHandlers({ socket: this.socket, actions: this.actions, dispatch, token: this.token, handleSessionClose: this.handleSessionClose });
  }

  handleSessionClose = () => {
    this.isSesssionAlive = false;
  }

  getSocket = () => this.socket;
}

export { Socket, ChatSocket }
