export const languages = {
  fr: 'fr',
  en: 'en',
  ru: 'ru',
  es: 'es',
  fa: 'fa',
  pt: 'pt',
  it: 'it',
  ar: 'ar',
  zh: 'zh',
  az: 'az',
  am: 'am',
  ka: 'ka',
  ko: 'ko',
  tr: 'tr',
  uk: 'uk',
};