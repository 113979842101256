import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import { getIconById } from 'components/Header/img/icons';

import './Bets.scss';

const Bets = () => {
  const b = block('bets');
  const locale = useSelector(state => state.locale.locale);
  const bets = useSelector(state => state.history.bets) || [];

  const resultList = useMemo(() => (
    bets.map((t, i) => (
      <div key={`${t.user_id}${i}`} className={b('item', {winBet: t.isWin })}>
        <div className={b('column-icon')}>
          <img src={getIconById(t.icon_id)} className={b('icon')} loading='lazy' alt='icon' />
          {t.nick || t.user_id}
        </div>
        <div className={b('column')}>
          {`${t.bet_amount}${t.currency}`}
        </div>
        <div className={b('column')}>
          {t.coefficient ? `${t.coefficient}x` : '-'}
        </div>
        <div className={b('column')}>
          {t.win_amount ? `${t.win_amount}${t.currency}` : '-'}
        </div>
      </div>
    ))
  ), [b, bets])

  return <section className={b()}>
    <div className={b('header')}>
      <div className={b('column')}>
        {locale.player.toUpperCase()}
      </div>
      <div className={b('column')}>
        {locale.amount.toUpperCase()}
      </div>
      <div className={b('column')}>
        {locale.odd.toUpperCase()}
      </div>
      <div className={b('column')}>
        {locale.win.toUpperCase()}
      </div>
    </div>
    <div className={b('items')}>
      {resultList}
    </div>
  </section>
}

export default Bets;
