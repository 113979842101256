import React, { useCallback, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import { Settings } from 'features/Settings';
import { Jackpot } from 'features/Jackpot';
import { Rules } from 'components/Rules';
import Header from 'components/Header';
import tickets from './img/tickets.svg';
import info from './img/info.svg';
import settings from './img/settings.svg';
import TicketsComponent from './TicketsComponent';

import './Subheader.scss';

const Subheader = ({ switchIsOpenHistory, switchIsOpenEdit, isOpenEdit }) => {
  const b = block('subheader');
  const [isOpen, setOpen] = useState(false);
  const [freeBetsIsOpen, setFreeBetsIsOpen] = useState(false);
  const [rulesIsOpen, setRulesIsOpen] = useState(false);

  const onTicketsClick = useCallback(() => {
    setFreeBetsIsOpen(true);
  }, []);

  const onInfoClick = useCallback(() => {
    setRulesIsOpen(true);
  }, []);

  return <header className={b()}>
    <Header switchIsOpenEdit={switchIsOpenEdit} isOpenEdit={isOpenEdit} switchIsOpenHistory={switchIsOpenHistory} />
    {window.innerWidth <= 990 ? null : <Jackpot />}
    <div className={b('settings')}>
      <div className={b('item')} >
        <SVGInline svg={tickets} className={b('icon').toString()} onClick={onTicketsClick} />
      </div>
      <div className={b('item')}>
        <SVGInline svg={info} className={b('icon').toString()} onClick={onInfoClick} />
      </div>
      <div className={b('item')} onClick={() => setOpen(!isOpen)}>
        <SVGInline svg={settings} className={b('icon').toString()} />
        <div>
          {isOpen ? <Settings isOpen={isOpen} /> : null}
        </div>
      </div>
    </div>
    <TicketsComponent onClose={() => setFreeBetsIsOpen(false)} isOpen={freeBetsIsOpen} />
    <Rules isOpen={rulesIsOpen} onClose={() => setRulesIsOpen(false)} />
  </header>
}

export default Subheader;