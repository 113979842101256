import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import close from 'services/img/close_gr.svg';
import JackpotCell from '../JackpotItem/JackpotCell';

import './JackpotWindow.scss';

const b = block('jackpot-window');

const JackpotWindow = ({ jackpot, closeWindow, bodyColor, headerColor, cellColor, headerIcon }) => {
  const currency = useSelector(state => state.auth.currency);
  const locale = useSelector(state => state.locale.locale);
  const amount = Array.from(String((jackpot?.winner_amount?.[currency]).toFixed(0)).padStart(6, '0'));

  return <section className={b()}>
    <div className={b('container')} style={{ background: bodyColor }}>
      <div className={b('header')} style={{ background: headerColor }}>
        <div className={b('header-content')}>
          <SVGInline svg={headerIcon} alt='svg' className={b('header-icon').toString()} />
          <div className={b('header-text')}>{locale.jackpot}</div>
        </div>
        <SVGInline svg={close} className={b('svg').toString()} alt='close' onClick={closeWindow} />
      </div>
      <div className={b('content')}>
        <div className={b('bets-container')}>
          {locale.youWonJackpot}
          <div className={b('cell-curency')}>
          <div className={b('cell-container')}>
            {amount.map((item, i) => <JackpotCell key={i} value={item} cellColor={cellColor} width='2rem' />)}
          </div>
          <div className={b('currency')}>{currency}</div>
          </div>
          <div className={b('button')} style={{color: cellColor}} onClick={closeWindow}>{locale.closeJackpot}</div>
        </div>
      </div>
    </div>
  </section>
}

export default JackpotWindow;