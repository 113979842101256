import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import fullStatictic from './img/icon-history.svg';
import StatisticComponent from './StatisticComponent';

import './Statistic.scss';

const Statistic = () => {
  const b = block('statistic');
  const coefsHistory = useSelector(state => state.game.coefsHistory);
  const [isOpen, setOpen] = useState(false);

  const coefs = useMemo(() => coefsHistory.map(coef => <div
    key={coef.game_id}
    className={b('coef')}
    style={{color: coef.crash_point <= 1.8 ? '#eb2020' : '#2ecd45'}}
  >{coef.crash_point}</div>),[b, coefsHistory]);

  return <section className={b()}>
    <div className={b('coefs-container')}>
      {coefs}
    </div>
    <SVGInline svg={fullStatictic} className={b('arrow', { isOpen }).toString()} onClick={() => setOpen(true)} />
    {isOpen ? <StatisticComponent setOpen={setOpen} /> : null}
  </section>
}

export default Statistic;