import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions as historyActions } from 'features/History';

import Firework from './Firework';
import WinningPopup from './WinningPopup';
import Coef from './Firework/Coef';
import bg from './img/background.jpg';

import styles from '../../data';

import './GameWindow.scss';

const b = block('game-window');

const GameWindow = () => {
  const dispatch = useDispatch();
  const [isPause, setPause] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const { periodType, styleCode, isWinPopup } = useSelector(state => state.game);
  const isAnimations = useSelector(state => state.settings.isAnimations);

  useEffect(() => {
    if (periodType === 2) {
      setPause(false)
      dispatch(historyActions.clearBets());
      if (!timeoutId) {
        setTimeoutId(setTimeout(() => setPause(true), 2250));
      }
    }
    if (periodType === 1) {
      setPause(false)
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null)
      }
    }
  }, [periodType, dispatch, timeoutId])

  const endObject = useMemo(() => styles[styleCode].endObjectAfter ? <img
    className={b('end-image')}
    src={periodType === 2 && !isPause ? styles[styleCode].endObjectAfter : null}
    alt="" /> : null, [isPause, periodType, styleCode])

  return <article className={b({
      isAnimation: !isPause && isAnimations,
      isPauseAnimation: periodType === 2 && !isPause,
      styleCode: styleCode || 'default'
    })}
      style={{ background: `url(${bg})`, backgroundSize: '200% 400%', backgroundPosition: '0 100%' }}>
        <Firework isPause={isPause} />
        <Coef />
        {isWinPopup && <WinningPopup />}
        {endObject}
    </article>

}

export default GameWindow;