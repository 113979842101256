import React from 'react';
import block from 'bem-cn';

import './SocketErrorBoundary.scss';

const b = block('socket-error');

const SocketErrorBoundary = ({ error }) => {
    return (
        <div className={b()}>
            {error}
        </div>
    );
};

export default SocketErrorBoundary;