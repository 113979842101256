import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import './Coef.scss';

const b = block('coef');

const Coef = () => {
  const coef = useSelector(state => state.game.coef);

  return <div className={b()}>
    {`${coef.toFixed(2)} x`}
  </div>;
}

export default Coef;