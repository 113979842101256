import React from 'react';
import block from 'bem-cn';

import './JackpotCell.scss';

const b = block('jackpot-cell');

const JackpotCell = ({ value, cellColor, width }) => {
    return (
        <div className={b()} style={{background: cellColor, width: width}}>
            {value}
        </div>
    );
};

export default JackpotCell;