import React, { useCallback, useState } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';

import close from 'services/img/close_gr.svg';

import './Rules.scss';

const Rules = ({ isOpen, onClose }) => {
  const b = block('rules');
  const { locale } = useSelector(state => state.locale);
  const { currency } = useSelector(state => state.auth);
  const { win } = useSelector(state => state.game.limits);

  const [isClosing, setIsClosing] = useState(false);

  const handleClose = useCallback(() => {
      setIsClosing(true);
      setTimeout(() => {
          setIsClosing(false);
          onClose();
      }, 300);
  }, [onClose]);

  if (!isOpen) {
      return null;
  }

  return <article className={b()}>
    <div className={b('container', { isClose: isClosing })}>
      <div className={b('header')}>
        <div className={b('lable')}>{locale.rules}</div>
        <SVGInline svg={close} className={b('svg').toString()} alt='close' onClick={handleClose} />
      </div>
      <div className={b('content')}>
        <section className={b('text')} dangerouslySetInnerHTML={{ __html: locale.rulesHtml(win, currency) }} />
      </div>
    </div>
  </article>
}

export default Rules;