import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions } from '../../';

import { icons } from 'components/Header/img/icons';

import './EditUser.scss';

const b = block('edit-user');

const EditUser = ({ setIsOpenEdit }) => {
  const dispatch = useDispatch();
  const { icon_id } = useSelector(state => state.auth);

  const updateUser = useCallback((id) => {
    dispatch(actions.userUpdate({ icon_id: +id }))
    setIsOpenEdit(false);
  }, [dispatch, setIsOpenEdit])

  const iconsList = useMemo(() => Object.entries(icons).map((t, index) => <img
    className={b('icon', { active: icon_id.toString() === t[0]}).toString()}
    src={t[1]}
    key={index}
    loading='lazy'
    alt='icon'
    onClick={() => updateUser(t[0])}
  />), [icon_id, updateUser])

  return <article className={b()}>
  <div className={b('icons-block')}>
    {iconsList}
  </div>
</article>;
}

export default EditUser;