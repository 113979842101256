import en from './en-us.svg';
import az from './az-az.svg';
import es from './es-es.svg';
import fa from './fa-ir.svg';
import fr from './fr-fr.svg';
import am from './hy-am.svg';
import it from './it-it.svg';
import ka from './ka-ge.svg';
import ko from './ko-kr.svg';
import pt from './pt-pt.svg';
import ru from './ru-ru.svg';
import tr from './tr-tr.svg';
import uk from './uk-ua.svg';
import zh from './zh-cn.svg';
import ar from './ar-ae.svg';

export const languageIcons = {
    'en': en,
    'az': az,
    'es': es,
    'fa': fa,
    'fr': fr,
    'am': am,
    'it': it,
    'ka': ka,
    'ko': ko,
    'pt': pt,
    'ru': ru,
    'tr': tr,
    'uk': uk,
    'zh': zh,
    'ar': ar,
}
