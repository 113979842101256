import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import { categories } from '../data';

import Basket from 'components/Basket';
import Subheader from 'components/Subheader';
import Statistic from 'components/Statistic';
import { History } from 'features/History';
import { Chat } from 'features/Chat';
import { Jackpot } from 'features/Jackpot';
import GameWindow from './GameWindow';
import DotsAnimation from './DotsAnimation';
import Pause from './Pause';

import './Game.scss';

const b = block('game');

const Game = () => {
  const [isPause, setPause] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const periodType = useSelector(state => state.game.periodType);
  const locale = useSelector(state => state.locale.locale);
  const [category, changeCategory] = useState(categories.play || '');

  useEffect(() => {
    if (periodType === 2) {
      setPause(false)
      if (!timeoutId) {
        setTimeoutId(setTimeout(() => setPause(true), 2250));
      }
    }
    if (periodType === 1) {
      setPause(false)
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null)
      }
    }
  }, [periodType, timeoutId])

  // User edit animation
  const [isOpenEdit, changeIsOpenEdit] = useState(false);

  const buttons = Object.entries(categories).map(t => (
    <div className={b('button')} key={t[0]} onClick={() => changeCategory(t[1])} style={{ color: category.id === t[1]?.id ? '#ffcc00' : '#8d97a1' }}>
      <SVGInline svg={category.id === t[1]?.id ? t[1]?.choosenIcon : t[1]?.icon} />
      {locale[t[1].textIdent].toUpperCase()}
    </div>
  ))

  const neededComponent = useMemo(() => {
    switch (category.id) {
      case 0:
        return (
          <div className={b('game-components')}>
            <div className={b('game-layout')}>
              {(isPause && periodType === 2)
                ? <Pause />
                : <>
                  <div className={b('vertical-animation')}>
                    <DotsAnimation isVertical={true} />
                    <div className={b('game-window-container')}>
                      <GameWindow />
                    </div>
                  </div>
                  <DotsAnimation isVertical={false} />
                </>
              }
            </div>
            <div className={b('statistic-layout')}>
              <Statistic />
            </div>
            <div className={b('basket-layout')}>
              <Basket />
            </div>
          </div>);
      case 1:
        return (
          <div className={b('history-layout')}>
            <History />
          </div>);
      case 2:
        return (
          <div className={b('chat-layout')}>
            <div className={b('chat-container')} >
              <Chat />
            </div>
          </div>);
      default:
        return null;
    }
  }, [category.id, isPause, periodType]);

  return <article className={b()}>
    {window.innerWidth > 1279 ? <React.Fragment>
      <div className={b('left-header-layout')}>
        <Subheader isOpenEdit={isOpenEdit} />
      </div>
      <div className={b('content')}>
        <div className={b('history-layout')}>
          <History />
        </div>
        <div className={b('game-components')}>
          <div className={b('game-layout')}>
            {(isPause && periodType === 2)
              ? <Pause />
              : <>
                <div className={b('vertical-animation')}>
                  <DotsAnimation isVertical={true} />
                  <div className={b('game-window-container')}>
                    <GameWindow />
                  </div>
                </div>
                <DotsAnimation isVertical={false} />
              </>
            }
          </div>
          <div className={b('statistic-layout')}>
            <Statistic />
          </div>
          <div className={b('basket-layout')}>
            <Basket />
          </div>
        </div>
        <div className={b('chat-layout')}>
          <div className={b('chat-container')} >
            <Chat />
          </div>
        </div>
      </div>
      <div className={b('footer')}>
        <div>{locale.rights}</div>
        <div>{locale.poweredBy}</div>
      </div>
      {/* <MegaWins /> */}
    </React.Fragment>
      : window.innerWidth > 990 ? <React.Fragment>
        <div className={b('left-header-layout')}>
          <Subheader />
        </div>
        <div className={b('content')}>
          {neededComponent}
        </div>
        <div className={b('footer')}>
          {buttons}
        </div>
      </React.Fragment>
        : <React.Fragment>
          <div className={b('left-header-layout')}>
            <Subheader />
          </div>
          <div className={b('jackpot-layout')}>
            <Jackpot />
          </div>
          <div className={b('content')}>
            {neededComponent}
          </div>
          <div className={b('footer')}>
            {buttons}
          </div>
        </React.Fragment>
    }
  </article>
}

export default Game;